.sign-in, .sign-up {
	width: 360px;
	padding: 8% 0 0;
	margin: auto;

	.form {
		padding: 45px;
		box-shadow: 1px -1px 30px 0px rgba(0, 0, 0, 0.15);
		-webkit-box-shadow: 1px -1px 30px 0px rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 1px -1px 30px 0px rgba(0, 0, 0, 0.15);
		margin: 0 auto 100px;
		border-radius: 20px;

		.rat-img {
			width: 100%;
			height: auto;
		}

		.login-input {
			border-radius: 30px;
			padding-left: 20px;
		}

		.forgot-btn {
			margin-top: 8px;
			font-size: 12px;
			color: #000;
		}

		.sign-in-btn {
			margin: 45px 0 25px 0;
			background: #1b3b6f;
			border: 0;
			padding: 10px;
			color: #ffffff;
			font-size: 14px;
			border-radius: 30px;
		}

		.home-button {
			background-color: transparent;
			border-color: #1b3b6f;
			padding: 5px 30px;
			border-radius: 30px;
            color: #1b3b6f;
		}

		.message {
			margin: 15px 0 0;
			color: #b3b3b3;
			font-size: 12px;

			a {
				color: #1b3b6f;
				text-decoration: none;
			}
		}

		.error {
			color: red;
			font-size: 12px;
			margin-left: 10px;
		}
	}
}


.sign-up {
	.form {
		.signup-input {
			border-radius: 30px;
			padding-left: 20px;
		}
		.termsCheckbox {
			margin-left: 5px;
			padding-left: 5px;
		}
		.termsCheckboxLabel {
			font-size: 0.8em;
			padding-left: 4px;
			color: gray;
			position: relative;
			bottom: 2px;
		}
		.sign-up-btn {
			margin: 45px 0 25px 0;
			background: #1b3b6f;
			border: 0;
			padding: 10px;
			color: #ffffff;
			font-size: 14px;
			border-radius: 30px;
		}
		.reCaptcha-size {
			transform: scale(0.9);
			transform-origin: 0 0;
		}
	}
}
