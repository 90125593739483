.resend-verification {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;

  .resend-heading {
    text-align: center;
    margin-top: 2px;
    margin-bottom: 15px;
  }

  .resend-subtitle {
    text-align: center;
    font-size: 14px;
  }

  .form {
    padding: 45px;
    box-shadow: 1px -1px 30px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 1px -1px 30px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px -1px 30px 0px rgba(0, 0, 0, 0.15);
    margin: 0 auto 100px;
    border-radius: 20px;

    .rat-img {
      width: 100%;
      height: auto;
    }

    .icon-div {
      text-align: center;
      padding: 10px;
    }

    .icon-check {
      color: #0fe661;
      height: 40px;
      width: 40px;
    }

    .icon-cross {
      color: #b40808;
      height: 40px;
      width: 40px;
    }

    .resend-input {
      border-radius: 30px;
      padding-left: 20px;
    }

    .resend-verify-up-btn {
      margin: 45px 0 25px 0;
      background: #1b3b6f;
      border: 0;
      padding: 10px;
      color: #ffffff;
      font-size: 14px;
      border-radius: 30px;
    }

    .error {
      color: red;
      font-size: 12px;
      margin-left: 10px;
    }

    .home-button {
      background-color: transparent;
      border-color: #1b3b6f;
      padding: 5px 30px;
      border-radius: 30px;
      color: #1b3b6f;
    }
  }
}
