@font-face {
	font-family: tiger;
	src: url("tiger.woff");
}

@font-face {
	font-family: varitykiller;
	src: url("Varietykiller.otf");
}

@font-face {
	font-family: "OpenSans";
	src: url("OpenSans-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

/* Define @font-face for OpenSans-SemiBold */
@font-face {
	font-family: "OpenSans";
	src: url("OpenSans-SemiBold.ttf") format("truetype");
	font-weight: 600; /* 600 is commonly used for semi-bold */
	font-style: normal;
}

/* Define @font-face for OpenSans-Bold */
@font-face {
	font-family: "OpenSans";
	src: url("OpenSans-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Source Sans Pro";
	src: url("SourceSansPro-Regular.ttf");
}

@font-face {
	font-family: "Poppins";
	src: url("Poppins-SemiBold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: "Poppins";
	src: url("Poppins-Medium.ttf");
	font-weight: 500;
}

@font-face {
	font-family: Gabarito;
	src: url("gabarito.ttf");
	font-weight: 500;
}

@font-face {
	font-family: Heebo;
	src: url("Heebo/Heebo-Regular.ttf");
	font-weight: 500;
}

@font-face {
	font-family: Heebo;
	src: url("Heebo/Heebo-Bold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: Heebo;
	src: url("Heebo/Heebo-ExtraBold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: "Montserrat";
	src: url("Montserrat-VariableFont_wght.ttf");
}

@font-face {
	font-family: "Slug and Lion";
	src: url("TheSlugandLion.otf");
}

@font-face {
	font-family: Roboto;
	src: url("Roboto-Medium.ttf");
}

@font-face {
	font-family: Grotesk;
	src: url("ClashGrotesk-Variable.ttf");
}
