.thick {
	font-weight: 600;
	font-size: 2em;
	font-family: "Slug and Lion" !important;
}

.overline {
	font-weight: "lighter";
	font-size: 1em;
	text-transform: uppercase;
	opacity: 0.5;
	font-family: Roboto, Helvetica, sans-serif;
	margin-bottom: 0px;
	line-height: 3px;
}

.white-label-image {
	width: 70%;
	height: auto;
}

.powered-by {
	color: #1b3b6f;
	font-size: 0.8em;
	font-family: Roboto, Helvetica, sans-serif;
}
