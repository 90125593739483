@import "../../assets/fonts/fonts.css";

.room {
	.enter-room {
		@media screen and (min-width: 1090px) {
			max-width: 1090px;
			margin: 0 auto;
		}
	}
	h2 {
		font-family: "Slug and Lion", Arial, Helvetica, sans-serif;
	}
	.content-wrapper {
		margin-top: calc(53px + 10%);
		position: relative;
		//---maybe wrong
		height: calc(90vh - 97px);
	}
	.heebo {
		font-family: "Anton", sans-serif;
		font-weight: bold;
	}
	.slug-and-lion {
		font-family: "Slug and Lion", Arial, Helvetica, sans-serif;
	}

	.room-title {
		font-family: "Slug and Lion", Arial, Helvetica, sans-serif;
		font-size: 42px;
		text-transform: uppercase;
		color: #21295c;
	}

	.error {
		font-size: 12px;
		color: #db433c;
		margin-left: 5px;
		margin-top: 5px;
	}

	.main-content {
		background: rgba(158, 179, 194, 0.1);
		border-radius: 10px;
		margin: 0 10px;

		.name-enter {
			opacity: 1;
		}
		.name-enter-active {
			transition: background-color 400ms;
			background-color: rgba(28, 114, 147, 0.3);
		}
		.name-enter-done {
			opacity: 1;
			transition: all 400ms;
		}

		.content-title {
			font-family: Heebo;
			text-align: center;
			font-size: 18px;
			text-transform: uppercase;
			color: #21295c;
			padding-top: 10px;
		}

		form {
			margin: 0 14px 20px;

			input {
				font-family: "Gabarito";
				text-transform: uppercase;
				height: 83px;
				font-size: 44px;
				color: #21295c;
			}
		}

		.btn-primary {
			background: #1c7293;
			font-size: 22px;
			font-family: Heebo;
			text-transform: uppercase;
			font-weight: 400;
			border: none;
		}
	}

	.room-doesnt-exist {
		background: rgba(158, 179, 194, 0.1);
		border-radius: 10px;
		text-align: center;
		margin-top: calc(53px + 10%);
		position: relative;

		h2 {
			color: #1b3b6f;
		}

		.btn-primary {
			background: #1c7293;
			font-size: 13px;
			font-family: Heebo;
			text-transform: uppercase;
			font-weight: 400;
			border: none;

			&:not(:disabled):not(.disabled):active {
				background-color: #4e8da8;
				border-color: #4e8da8;
			}
		}
	}

	.media-buttons {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		margin-left: 0;
		width: 100%;
		padding-top: 8px;
		background-color: #ffffff;

		.btn-secondary {
			//background: #9eb3c2;
			background: #26516d;
			color: #ffffff;
			font-size: 13px;
			text-transform: uppercase;
			font-family: "Arial";
			border: none;
		}

		.zoom-btn {
			background: #007bff;
			border-radius: 25px;
			color: white;
			height: 25px;
			width: 25px;

			&:hover {
				background: #1b3b6f;
			}

			.svg-inline--fa {
				font-size: 14px;
				margin-left: 5px;
			}
		}
		.twitch-btn {
			color: #772ce8;
			font-size: 23px;

			&:hover {
				color: #1b3b6f;
			}

			.svg-inline--fa {
				margin-bottom: 4px;
			}
		}
		.trivia-media {
			.svg-inline--fa {
				font-size: 21px;
				color: #1b3b6f;
			}
		}
	}

	.with-ad {
		bottom: 90px;
	}
}
