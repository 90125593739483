@import "../../../assets/fonts/fonts.css";
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;500&family=Josefin+Sans:wght@300;400;500;600&display=swap");

.player-mode {
	h1,
	h2,
	h3,
	h4,
	h5,
	input {
		font-family: "Slug and Lion";
		color: #21295c;
	}

	.tiger {
		font-family: "tiger";
	}
	.varitykiller {
		font-family: "varitykiller";
	}
	.heebo {
		font-family: Heebo;
		font-weight: bold;
	}
	.slug-and-lion {
		font-family: "Slug and Lion";
	}

	.board-wrapper {
		margin-top: 53px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 10px;
		justify-content: space-between;
		@media screen and (min-width: 1090px) {
			max-width: 1090px;
			margin: 0 auto;
		}
		.main-board {
			padding-top: 7%;
			h5 {
				font-size: 9px;
			}
			h4 {
				font-size: 20px;
			}
			h2 {
				font-size: 26px;
			}
		}
		.row {
			margin-left: 0;
			margin-right: 0;
		}
		.response {
			background: rgba(158, 179, 194, 0.1);
			border-radius: 10px;
			display: block;
			width: 100%;

			p {
				font-size: 17px;
				font-weight: 500;
				text-transform: uppercase;
				color: #21295c;
				margin-bottom: 0;

				&.error {
					color: red;
					font-size: 12px;
					margin-top: -12px;
				}
			}

			.pre-entered {
				@media screen and (min-width: 500px) {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
				@media screen and (max-width: 500px) {
					display: flex;
					text-align: center;
					align-items: center;
					margin: 10px;
					h5 {
						margin: 10px 10px 0;
					}
				}
			}

			.variants {
				text-align: center;
				padding: 0 15px;
				// color: #21295c;
			}

			.question-limit {
				text-align: center;

				.svg-inline--fa {
					color: #21295c;
				}
			}

			.question-number {
				margin-bottom: -11px;
				opacity: 0.7;
			}
			@keyframes slideInFromLeft {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}
			.question-image {
				max-width: 222px;
				height: auto;
				animation: 1s ease-out 0s 1 slideInFromLeft;
			}

			.player-input {
				box-sizing: content-box;
				font-family: "tiger";
				text-transform: uppercase;
				color: rgb(94, 93, 93);
				line-height: 40px;
				padding-bottom: 0px;
				padding-top: 9px;
				width: 95%;
				font-size: 38px;
				height: 70px;
				border-radius: 10px;
			}

			.centered {
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.submitted-border {
				border: 3px solid #13b83f;
			}

			.unsubmitted-border {
				border: 3px solid #1c7293;
			}

			.form-group {
				margin: 0 1rem 1rem 1rem;
				.form-control {
					//height: 83px;
					//font-size: 65px;

					line-height: 40px;
					max-width: 400px;

					margin: 0 auto;
				}

				.select {
					max-width: 400px;
					margin: 0 auto;
					margin-bottom: 1rem;
					.menu {
						max-height: 250px;
					}
				}
			}

			.btn-primary {
				background: #1c7293;
				font-size: 22px;
				font-family: Heebo;
				text-transform: uppercase;
				font-weight: 400;
				border: none;
			}

			.btn-secondary {
				background: #9eb3c2;
				font-size: 22px;
				color: #ffffff;
				border: none;
			}

			.jump-to {
				font-size: 13px;
				font-weight: 500;
				color: #21295c;
			}
			.buttons-scroller {
				background: #ffffff;
				padding: 10px;
				width: 96%;
				// max-width: 400px;

				margin: 0 auto;
				margin-bottom: 28px;
				.slick-slider {
					.slick-slide {
						//max-width: 70px !important;
						display: flex;
						justify-content: space-between;
					}
					.btn {
						margin: 0 10px;
						height: 42px;
						width: 42px !important;
						font-family: "varitykiller";
						font-size: 20px;
						font-weight: 400;
						text-align: center;
						box-shadow: none;
						border: none;
					}

					.btn-outline-primary {
						color: #1c7293;
						border-color: #1c7293;
						border: 1px solid;
						&:hover,
						&:not(:disabled):not(.disabled):active {
							background-color: #1c7293;
							color: #fff;
						}
					}
					.active {
						background: #1b3b6f;
					}
					.empty {
						border: 2px solid #1b3b6f;
						background: #ffffff;
						color: #1b3b6f;
					}
				}
			}
			.bet {
				margin-top: 5px;
				margin-bottom: 5px;
			}
		}

		.slick-slider .btn:first-child {
			margin-left: 0;
		}

		.slick-slider .btn:last-child {
			margin-right: 0;
		}

		.table-wrapper {
			margin: 0 10px;

			.table {
				thead {
					background: #1c7293;
					color: #ffffff;
					font-family: Heebo;
					font-weight: 900;
					font-size: 13px;
					border-top: none;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
					tr {
						border-top-left-radius: 10px;
						border-top-right-radius: 10px;
						th {
							&:first-child {
								border-top-left-radius: 10px;
							}
							&:last-child {
								border-top-right-radius: 10px;
							}
						}
					}
				}
				td {
					border-top: none;
					&:first-child {
						width: 20px;
					}
				}
				th {
					border: none;
				}
				tbody {
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					max-height: calc(55vh);
					tr:nth-of-type(odd) {
						background: #e5eaee;
					}
					tr:nth-of-type(even) {
						background: #f6f8f9;
					}
				}
			}
			table {
				width: 100%;
			}

			thead,
			tbody,
			tr {
				display: table;
				width: 100%;
				table-layout: fixed;
			}

			tbody {
				display: block;
				overflow: auto;
				table-layout: fixed;
				max-height: 350px;

				&::-webkit-scrollbar {
					height: 8px;
					width: 5px;
					background-color: #aaa;
				}
				&::-webkit-scrollbar-thumb {
					background: #000;
				}
			}

			.pause-game-message {
				background: rgba(158, 179, 194, 0.1);
				// background: #e5eaee;
				border-radius: 10px;
				display: block;
				width: 100%;
				padding: 80px 0;
			}
		}

		.correct-answer {
			font-size: 12px;
			line-height: 10px;
			margin: 0px;
			margin-top: 2px;
			font-style: italic;
			color: #1c7293;
		}

		.marking-wrapper {
			margin: 0 10px;
			display: block;
			overflow: auto;
			height: 90%;
			.marking {
				background: rgba(158, 179, 194, 0.1);
				border-radius: 10px;
				padding: 20px;

				.player-answer {
					background-color: #e2e3e5;
					width: 80%;
					text-align: center;
					padding: 0.75rem 1.25rem;
					margin-bottom: 10px;
					border-radius: 0.25rem;
					font-family: "Slug and Lion";
					color: #21295c;
				}

				.point {
					color: #155724;
					background-color: #d4edda;
					border-color: #c3e6cb;
				}

				.half-point {
					background: linear-gradient(to right, #e0eee0, #d6d8db);
				}

				.wrong {
					color: #d72f29;
					background-color: #fb9b98;
					border-color: #fb9b98;
				}
			}
		}
	}

	.media-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		background-color: #ffffff;
		margin-left: 0;
		margin-right: 0;
		height: 90px;
		margin-top: 5%;

		@media screen and (orientation: landscape) {
		}
		.custom-url {
			.btn-secondary {
				background: #26516d;
				color: #ffffff;
				font-size: 13px;
				text-transform: uppercase;
				font-family: "arial";
				border: none;
			}
		}
		.social-medias {
			display: flex;
			margin-top: 2%;
			.zoom-btn {
				background: #007bff;
				border-radius: 25px;
				color: white;
				height: 25px;
				width: 25px;

				&:hover {
					background: #1b3b6f;
				}

				.svg-inline--fa {
					font-size: 14px;
					margin-left: 5px;
				}
			}
			.twitch-btn {
				color: #772ce8;
				font-size: 23px;

				&:hover {
					color: #1b3b6f;
				}

				.svg-inline--fa {
					margin-bottom: 4px;
				}
			}
			.trivia-media {
				.svg-inline--fa {
					font-size: 21px;
					color: #1b3b6f;
				}
			}
		}
	}

	.my-node-enter {
		opacity: 0;
		transition: opacity 500ms ease-in;
	}
	.my-node-exit {
		opacity: 1;
		transition: opacity 500ms ease-in;
	}
}

.with-ad {
	margin-bottom: 90px;
}
